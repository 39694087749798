import {
  Button,
  Flex,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

import balanceImg from 'assets/img/dashboards/balanceImg.png';
import Card from 'components/card/Card.js';
import { useState } from 'react';
import { IoAddCircleOutline, IoTrashOutline } from 'react-icons/io5';
import { MdOutlineMonitorWeight } from 'react-icons/md';
import { VscFoldDown, VscFoldUp } from 'react-icons/vsc';
import PesoForm from 'views/admin/seco/entradas/components/Peso por unidad o transporte/components/PesoForm';

export default function Peso({
  isSubmitted,
  handleFocus,
  handleWeightEntryChange,
  weightEntries,
  setWeightEntries,
  caracteristica,
  editMode,
  isLoading,
  handleSubmit,
  setConfirm,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const [collapsed, setCollapsed] = useState(false);

  const toggleAllOnShow = () => {
    setWeightEntries((prevEntries) =>
      prevEntries.map((entry) => ({
        ...entry,
        onShow: !collapsed,
      })),
    );
    setCollapsed(!collapsed);

    const container = document.querySelector('.css-8y8lw5');
    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const toggleShow = (id) => {
    setWeightEntries((prevEntries) => {
      const updatedEntries = prevEntries.map((entry) => {
        if (entry.id === id && entry.onShow) {
          // Scroll to top if the entry's onShow is false
          const container = document.querySelector('.css-8y8lw5');
          if (container) {
            container.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
        return {
          ...entry,
          onShow: entry.id === id ? !entry.onShow : false,
        };
      });
      return updatedEntries;
    });
  };

  const addEntry = () => {
    const addNewEntry = () => {
      const nextInteger =
        weightEntries.length > 0
          ? Math.max(...weightEntries.map((entry) => entry.id)) + 1
          : 1;
      setWeightEntries([
        ...weightEntries,
        {
          id: nextInteger,
          chofer: 'Jorge Cruz Vázquez',
          placas: 'RY-30-570',
          marca: 'Ford',
          unidad: '1',
          cumple: 'Cumple',
          pesoConCarga: '0.00',
          pesoSinCarga: '0.00',
          yutes: '0',
          cajas: '0',
          lonas: '0',
          tara: '0',
          pesoBruto: '0.00',
          pesoNeto: '0.00',
          pesoPorSaco: '0.00',
          humedad: '0.00',
          descuentoHumedad: '0.00',
          descuentoHumedadPeso: '0.00',
          precioPrevio: '0.00',
          precioKg: '0.00',
          precioTotal: '0.00',
          caracteristica: '',
          ticketBascula: '',
          observaciones: '',
        },
      ]);

      // Close the modal
      setConfirm({ state: false });
    };

    setConfirm({
      state: 'true',
      title: 'Añadir registro',
      subtitle: `¿Estás seguro de querer añadir un nuevo registro?`,
      icon: <Icon as={IoAddCircleOutline} w="24px" h="24px" />,
      primaryBtnText: 'Confirmar',
      primaryBtnAction: addNewEntry, // Calls the function to add entry and close modal
      secondaryBtnText: 'Volver',
      secondaryBtnAction: () => setConfirm({ state: false }), // Closes the modal
    });
  };

  const deleteEntry = (idToDelete) => {
    const confirmDelete = () => {
      setWeightEntries((prevState) => {
        const filteredWeightEntries = prevState.filter(
          (item) => item.id !== idToDelete,
        );
        const updatedWeightEntries = filteredWeightEntries.map(
          (item, index) => ({
            ...item,
            id: index + 1,
          }),
        );

        return updatedWeightEntries;
      });

      setConfirm({ state: false });
    };

    setConfirm({
      state: 'true',
      title: 'Eliminar registro',
      subtitle: `¿Estás seguro de querer eliminar este registro?`,
      icon: <Icon as={IoTrashOutline} w="24px" h="24px" />,
      primaryBtnText: 'Eliminar',
      primaryBtnAction: confirmDelete, // Calls the function to delete entry and close modal
      secondaryBtnText: 'Cancelar',
      secondaryBtnAction: () => setConfirm({ state: false }),
    });
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
        minH={'80px'}
        className="sticky-header"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <MdOutlineMonitorWeight color="white" size={'25px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Peso por unidad o transporte
            </Text>
            <Text
              ms="auto"
              color={textColor}
              fontSize="sm"
              me="6px"
              fontWeight="700"
            ></Text>
          </Flex>
          <Flex gap={'30px'} alignItems={'center'}>
            <Button
              onClick={handleSubmit}
              isLoading={isLoading}
              loadingText={
                editMode ? 'Editando registro...' : 'Realizando registro...'
              }
              colorScheme="green"
            >
              {editMode ? 'Editar entrada' : 'Registrar entrada'}
            </Button>
            <Tooltip
              hasArrow
              label={collapsed ? 'Contraer registros' : 'Expandir registros'}
              bg="gray.300"
              color="black"
            >
              <Button onClick={toggleAllOnShow} w={'56px'}>
                {collapsed ? (
                  <VscFoldDown fontSize="1.2rem" />
                ) : (
                  <VscFoldUp fontSize="1.2rem" />
                )}
              </Button>
            </Tooltip>
            <Tooltip
              hasArrow
              label="Añadir registro"
              bg="gray.300"
              color="black"
            >
              <Button onClick={addEntry} w={'56px'}>
                <IoAddCircleOutline fontSize="1.5rem" />
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction={'column'}>
        {weightEntries.length > 0 &&
          weightEntries.map((x) => (
            <PesoForm
              key={x.id}
              id={x.id}
              weightEntry={x}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleWeightEntryChange={handleWeightEntryChange}
              deleteEntry={deleteEntry}
              caracteristica={caracteristica}
              editMode={editMode}
              onShow={x.onShow}
              toggleShow={toggleShow}
            />
          ))}
      </Flex>
    </Card>
  );
}
