import {
  Icon,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Button,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  Select,
  Center,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import Card from 'components/card/Card.js';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { formatTableValue } from 'utils/format';
import styles from '../../../../../assets/css/Table.module.css';

function SalidaTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card mb={{ base: '20px', xl: '0px' }}>
      <Flex direction="column" w="100%">
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          px="10px"
          className={styles.fixed_header}
        >
          <Text
            color={textColor}
            fontSize="lg"
            fontWeight="700"
            lineHeight="100%"
          >
            Tabla de registro de salidas
          </Text>
        </Flex>
        <Box overflowX="auto" mt="3rem">
          <Table {...getTableProps()} variant="simple" color="gray.500">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor="transparent"
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                        minWidth={'max-content'}
                      >
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            {page?.length > 0 ? (
              <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let data = '';
                        data = (
                          <Flex
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            align="center"
                          >
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="600"
                              minWidth={'max-content'}
                            >
                              {formatTableValue(
                                cell.column?.type,
                                cell.value,
                              ) || '-'}
                            </Text>
                          </Flex>
                        );

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            borderColor="transparent"
                            mt="20px !important"
                            py="10px !important"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            ) : (
              <Tbody>
                <Tr>
                  <Td colSpan={columnsData.length}>
                    <Flex
                      width="100%"
                      maxWidth="calc(100vw - 450px)"
                      height="200px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text>No se encontraron resultados.</Text>
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            )}
          </Table>
        </Box>
        {/* Pagination controls */}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mt="4"
          className={styles.fixed_footer}
        >
          <Flex>
            <Button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              mr={2}
            >
              {'<<'}
            </Button>
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              mr={2}
            >
              {'<'}
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage} mr={2}>
              {'>'}
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </Button>
          </Flex>
          <Flex alignItems="center">
            <Text mr={2}>
              Página{' '}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>
            </Text>
            <Box>
              <Select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                width="auto"
                ml={2}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}

export default SalidaTable;
