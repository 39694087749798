// Chakra imports
import {
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { AiOutlineSchedule } from 'react-icons/ai';
import { extractNumericValue } from 'utils/format';
import SecadoForm from 'views/admin/seco/secado/components/SecadoForm';

export default function Secado({
  secado,
  setSecado,
  isSubmitted,
  handleFocus,
  handleChange,
  secadoEntries,
  handleSecadoEntryChange,
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <AiOutlineSchedule color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Folios de secado
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        {secadoEntries.length > 0 &&
          secadoEntries.map((x) => (
            <SecadoForm
              key={x.id}
              secadoEntry={x}
              setSecado={setSecado}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleChange={handleChange}
              handleSecadoEntryChange={handleSecadoEntryChange}
            />
          ))}
        <Divider orientation="horizontal" my={'2rem'} />
        <SimpleGrid
          columns={{ base: '1', md: '4', lg: '6' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column"></Flex>
          <Flex direction="column"></Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Peso total
            </FormLabel>
            <InputGroup>
              <Input
                value={secado.pesoTotal}
                onChange={(e) => {
                  handleChange(
                    'pesoTotal',
                    extractNumericValue(e.target.value.trim()),
                  );
                }}
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Peso total"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>KG</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Pergamino total
            </FormLabel>
            <InputGroup>
              <Input
                value={secado.pergaminoTotal}
                onChange={(e) =>
                  handleChange(
                    'pergaminoTotal',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder=" Pergamino total"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>KG</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Pergaminera total
            </FormLabel>
            <InputGroup>
              <Input
                value={secado.pergamineraTotal}
                onChange={(e) =>
                  handleChange(
                    'pergamineraTotal',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Pergaminera total"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>KG</InputRightAddon>
            </InputGroup>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Merma total
            </FormLabel>
            <InputGroup>
              <Input
                value={secado.mermaTotal}
                onChange={(e) =>
                  handleChange(
                    'mermaTotal',
                    extractNumericValue(e.target.value.trim()),
                  )
                }
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Merma total"
                variant="auth"
                size="lg"
              />
              <InputRightAddon color={textColor}>KG</InputRightAddon>
            </InputGroup>
          </Flex>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: '1', md: '1', lg: '1' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Observaciones
            </FormLabel>
            <InputGroup>
              <Input
                value={secado.observaciones}
                onChange={(e) => handleChange('observaciones', e.target.value)}
                onFocus={handleFocus}
                borderRadius="6px"
                height="40px"
                fontSize="md"
                placeholder="Observaciones"
                variant="auth"
                size="lg"
              />
            </InputGroup>
          </Flex>
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
