export const tableColumnsEntradas = [
  {
    headerName: 'Cosecha',
    field: 'cosecha',
    width: 150,
  },
  {
    headerName: 'ID',
    field: 'registerId',
    width: 100,
  },
  {
    headerName: 'Entrada (Global)',
    field: 'entradaId',
    width: 150,
  },
  {
    headerName: 'Entrada (Cosecha)',
    field: 'cosechaId',
    width: 150,
  },
  {
    headerName: 'Fecha',
    field: 'fecha',
    dataType: 'date',
    width: 200,
  },
  {
    headerName: 'Región',
    field: 'region',
    width: 200,
  },
  {
    headerName: 'Comunidad',
    field: 'comunidad',
    width: 200,
  },
  {
    headerName: 'Productor',
    field: 'productor',
    width: 200,
  },
  {
    headerName: 'Tipo',
    field: 'tipo',
    width: 200,
  },
  {
    headerName: 'Chofer',
    field: 'chofer',
    width: 200,
  },
  {
    headerName: 'Característica',
    field: 'caracteristica',
    width: 200,
  },
  {
    headerName: 'Peso bruto',
    field: 'pesoBruto',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Yutes',
    field: 'yutes',
    width: 200,
  },
  {
    headerName: 'Lonas',
    field: 'lonas',
    width: 200,
  },
  {
    headerName: 'Cajas',
    field: 'cajas',
    width: 200,
  },
  {
    headerName: 'Tara',
    field: 'tara',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Peso neto',
    field: 'pesoNeto',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Precio por kilo',
    field: 'precioKg',
    dataType: 'pesos',
    width: 200,
  },
  {
    headerName: 'Humedad',
    field: 'humedad',
    dataType: 'percentage',
    width: 200,
  },
  {
    headerName: 'Descuento Humedad (MXN)',
    field: 'descuentoHumedad',
    dataType: 'pesos',
    width: 200,
  },
  {
    headerName: 'Descuento Humedad (KG)',
    field: 'descuentoHumedadPeso',
    dataType: 'kilograms',
    width: 200,
  },
  {
    headerName: 'Precio previo (sin humedad)',
    field: 'precioPrevio',
    dataType: 'pesos',
    width: 200,
  },
  {
    headerName: 'Precio total',
    field: 'precioTotal',
    dataType: 'pesos',
    width: 200,
  },
  {
    headerName: 'Observaciones',
    field: 'observaciones',
    width: 200,
  },
];
