import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Grid, useColorModeValue, Button } from '@chakra-ui/react';
import { MdOutlineDriveFileMove } from 'react-icons/md';
import { VSeparator } from 'components/separator/Separator';
import ResultMessage from 'components/modal/ResultMessage';
import { getAllSecado } from 'api/secado';
import { addOrdenMaquila } from 'api/maquila';
import MateriaPrima from './components/MateriaPrima';
import OrdenMaquila from 'views/admin/seco/maquila/components/OrdenMaquila';
import { useLocation } from 'react-router-dom';
import { OFERTA, ALERT } from 'constant/constant';

export default function MaquilaOrdenFolio() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const location = useLocation();
  const { selectedFolio } = location.state || {};

  const initialOrdenFolioState = {
    fecha: new Date(),
    entradaId: selectedFolio?.entradaId || '',
    folioSecado: selectedFolio?.folioSecado || '',
    estado: selectedFolio?.estado || 'No especificado',
    region: selectedFolio?.region || 'No especificado',
    municipio: selectedFolio?.municipio || 'No especificado',
    comunidad: selectedFolio?.comunidad || 'No especificado',
    productor: selectedFolio?.productor || 'No especificado',
    cosecha: selectedFolio?.cosecha || 'No especificado',
    tipo: selectedFolio?.tipo || 'No especificado',
    caracteristica: selectedFolio?.caracteristica || 'No especificado',
    claveDescarga: selectedFolio?.claveDescarga || 'No especificado',
    purgaTren: false,
    liberacionMateriaPrima: 'Cumple',
    maquilaTipo: '',
    numeroIco: '',
    preparacion: '',
    cliente: '',
    contrato: '',
    lote: '',
    kilogramos: '',
    rendimientoExportable: '',
    rendimientoTotal: '',
    observaciones: '',
  };

  const [secado, setSecado] = useState([]);
  const [ordenFolio, setOrdenFolio] = useState(initialOrdenFolioState);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const fetchAllSecado = async () => {
      try {
        setIsLoading(true);
        const response = await getAllSecado(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData =
            result?.data?.data.filter((x) => x.estatus !== 'salida') || [];
          setSecado(resultData);

          if (selectedFolio && resultData?.length > 0) {
            let selectedFolioByFlavorWheel = resultData.find(
              (x) => x.id === selectedFolio.folioSecado,
            );
            if (selectedFolioByFlavorWheel.estatus === 'oferta') {
              handleMessage(
                OFERTA.WARNING_ESTATUS,
                OFERTA.WARNING_TITLE,
                OFERTA.WARNING_SUBTITLE,
              );
            }
          }
        } else {
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllSecado();
  }, [selectedFolio]);

  const handleChange = (field, value) => {
    setOrdenFolio((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleAllTruthy = (obj, exceptions = []) => {
    const arrayOfObjects = Array.isArray(obj) ? obj : [obj];
    const values = arrayOfObjects.flatMap((item) => {
      const keys = Object.keys(item);
      const filteredKeys = keys.filter((key) => !exceptions.includes(key));
      return filteredKeys.map((key) => item[key]);
    });

    if (!values || values.length === 0) {
      return false;
    } else {
      return values.every((value) => !!value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const exceptions = ['observaciones', 'cosecha', 'purgaTren'];
    const allOrdenFolioPropertiesTruthy = handleAllTruthy(
      ordenFolio,
      exceptions,
    );

    if (allOrdenFolioPropertiesTruthy) {
      try {
        setIsLoading(true);
        setIsSubmitted(true);

        const response = await addOrdenMaquila(TOKEN, USERID, ordenFolio);
        if (response.status === 200) {
          setIsSubmitted(false);
          setIsLoading(false);
          handleMessage(
            'success',
            'Operación exitosa.',
            'La orden de maquila fue registrada correctamente.',
          );
          setOrdenFolio(initialOrdenFolioState);
          window.scrollTo({ top: 0, left: 0 });
        } else {
          setIsLoading(false);
          handleMessage(
            'error',
            'Error en la operación.',
            'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
          );
        }
      } catch (error) {
        setIsLoading(false);
        handleMessage(
          'error',
          'Error en la operación.',
          'Favor de intentar más tarde o ponerse en contacto con un agente de soporte.',
        );
        console.error(error);
      }
    } else {
      setIsSubmitted(true);
      handleMessage(
        'error',
        'Error en la operación.',
        'Favor de llenar todos los campos requeridos.',
      );
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <MateriaPrima
              secado={secado}
              ordenFolio={ordenFolio}
              setOrdenFolio={setOrdenFolio}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
              handleChange={handleChange}
              handleMessage={handleMessage}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <OrdenMaquila
              ordenFolio={ordenFolio}
              handleChange={handleChange}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
            />
          </Flex>
        </Grid>

        <Flex justify="center" my="40px">
          <Button
            onClick={handleSubmit}
            leftIcon={<MdOutlineDriveFileMove />}
            isLoading={isLoading}
            loadingText="Realizando orden..."
            bgColor={balanceBg}
            color="white"
            size="lg"
          >
            Generar orden de maquila
          </Button>
        </Flex>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
