import { useEffect, useState } from 'react';

// Chakra imports
import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import { FaRegUser } from 'react-icons/fa';

// React-redux
import RegisterCommunity from 'components/modal/register/RegisterCommunity';
import RegisterMunicipality from 'components/modal/register/RegisterMunicipality';
import RegisterProductor from 'components/modal/register/RegisterProductor';
import RegisterRegion from 'components/modal/register/RegisterRegion';
import RegisterState from 'components/modal/register/RegisterState';
import { getCosechaOptions } from 'utils/format';

export default function Proveedor({
  entrada,
  setEntrada,
  isSubmitted,
  handleFocus,
  handleChange,
  origen,
  productor,
  tipo,
}) {
  const modalButtonBg = useColorModeValue('brand.200', '#1B254B');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  const handleOrigen = (data, attribute, conditionName, conditionValue) => {
    const uniqueArray = [];
    const uniqueSet = new Set();
    if (data?.length > 0) {
      data.forEach((obj) => {
        const value = obj[attribute];

        if (value !== null && value && !uniqueSet.has(value)) {
          if (
            conditionName &&
            conditionValue &&
            conditionName !== conditionValue.toLowerCase() &&
            conditionValue !== 'No especificado'
          ) {
            if (obj[conditionName] === conditionValue) {
              uniqueSet.add(value);
              uniqueArray.push(value);
            }
          } else {
            uniqueSet.add(value);
            uniqueArray.push(value);
          }
        }
      });
      return ['No especificado', ...uniqueArray];
    } else {
      return [];
    }
  };

  const [estado, setEstado] = useState([]);
  const [region, setRegion] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [comunidad, setComunidad] = useState([]);
  const [cosecha, setCosecha] = useState([]);

  useEffect(() => {
    setEstado(handleOrigen(origen, 'estado'));
    setRegion(handleOrigen(origen, 'region'));
    setMunicipio(handleOrigen(origen, 'municipio'));
    setComunidad(handleOrigen(origen, 'comunidad'));

    setCosecha(getCosechaOptions());
  }, []);

  const selectedEstado = (estado) => {
    setRegion(handleOrigen(origen, 'region', 'estado', estado));
    setMunicipio(handleOrigen(origen, 'municipio', 'estado', estado));
    setComunidad(handleOrigen(origen, 'comunidad', 'estado', estado));
  };

  const selectedRegion = (region) => {
    setMunicipio(handleOrigen(origen, 'municipio', 'region', region));
    setComunidad(handleOrigen(origen, 'comunidad', 'region', region));
  };

  const selectMunicipio = (municipio) => {
    setComunidad(handleOrigen(origen, 'comunidad', 'municipio', municipio));
  };

  return (
    <Card direction="column" w="100%">
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
        minH="80px"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex alignItems="center" gap="15px" flexDirection="row" me="14px">
            <FaRegUser color="white" size={'20px'} />
            <Text
              color="white"
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              Datos del cliente o proveedor
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl>
        <SimpleGrid
          columns={{ base: '1', md: '3', lg: '4' }}
          gap={{ sm: '10px', md: '26px' }}
          mb={'1rem'}
        >
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Proveedor
              </FormLabel>
              <RegisterProductor bgButtonColor={modalButtonBg} />
            </Flex>
            <Select
              borderColor={isSubmitted && !entrada.productor ? 'red.500' : null}
              value={entrada.productor || ''}
              onChange={(e) => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                setEntrada((prevState) => ({
                  ...prevState,
                  productor: e.target.value,
                  estado: selectedOption?.dataset?.state || prevState.estado,
                  region: selectedOption?.dataset?.region || prevState.region,
                  municipio:
                    selectedOption?.dataset?.municipality ||
                    prevState.municipio,
                  comunidad:
                    selectedOption?.dataset?.community || prevState.comunidad,
                }));
              }}
              onFocus={handleFocus}
              variant="auth"
            >
              <option value="" disabled hidden>
                {entrada.productor || 'Proveedor'}
              </option>
              {productor?.length > 0 &&
                productor.map((x) => (
                  <option
                    key={x.id}
                    value={x.productor}
                    data-state={x.estado}
                    data-region={x.region}
                    data-municipality={x.municipio}
                    data-community={x.comunidad}
                  >
                    {x.productor}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Estado
              </FormLabel>
              <RegisterState bgButtonColor={modalButtonBg} />
            </Flex>
            <Select
              borderColor={isSubmitted && !entrada.estado ? 'red.500' : null}
              value={entrada.estado || ''}
              onChange={(e) => {
                handleChange('estado', e.target.value);
                selectedEstado(e.target.value);
              }}
              onFocus={handleFocus}
              variant="auth"
            >
              <option value="" disabled hidden>
                {entrada.estado || 'Estado'}
              </option>
              {estado?.length > 0 &&
                estado.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Región
              </FormLabel>
              <RegisterRegion bgButtonColor={modalButtonBg} />
            </Flex>
            <Select
              borderColor={isSubmitted && !entrada.region ? 'red.500' : null}
              value={entrada.region || ''}
              onChange={(e) => {
                handleChange('region', e.target.value);
                selectedRegion(e.target.value);
              }}
              onFocus={handleFocus}
              variant="auth"
            >
              <option value="" disabled hidden>
                {entrada.region || 'Región'}
              </option>
              {region?.length > 0 &&
                region.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Municipio
              </FormLabel>
              <RegisterMunicipality bgButtonColor={modalButtonBg} />
            </Flex>
            <Select
              borderColor={isSubmitted && !entrada.municipio ? 'red.500' : null}
              value={entrada.municipio || ''}
              onChange={(e) => {
                handleChange('municipio', e.target.value);
                selectMunicipio(e.target.value);
              }}
              onFocus={handleFocus}
              variant="auth"
            >
              <option value="" disabled hidden>
                {entrada.municipio || 'Municipio'}
              </option>
              {municipio?.length > 0 &&
                municipio.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <Flex alignItems={'center'}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Comunidad
              </FormLabel>
              <RegisterCommunity bgButtonColor={modalButtonBg} />
            </Flex>
            <Select
              borderColor={isSubmitted && !entrada.comunidad ? 'red.500' : null}
              value={entrada.comunidad || ''}
              onChange={(e) => {
                handleChange('comunidad', e.target.value);
              }}
              onFocus={handleFocus}
              variant="auth"
            >
              <option value="" disabled hidden>
                {entrada.comunidad || 'Comunidad'}
              </option>
              {comunidad?.length > 0 &&
                comunidad.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Cosecha
            </FormLabel>
            <Select
              borderColor={isSubmitted && !entrada.cosecha ? 'red.500' : null}
              value={entrada.cosecha || ''}
              onChange={(e) => handleChange('cosecha', e.target.value)}
              onFocus={handleFocus}
              variant="auth"
            >
              <option value="" disabled hidden>
                {entrada.cosecha || 'Cosecha'}
              </option>
              {cosecha?.length > 0 &&
                cosecha.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex direction="column">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Tipo de café
            </FormLabel>
            <Select
              borderColor={isSubmitted && !entrada.tipo ? 'red.500' : null}
              value={entrada.tipo || ''}
              onChange={(e) => handleChange('tipo', e.target.value)}
              onFocus={handleFocus}
              variant="auth"
            >
              <option value="" disabled hidden>
                {entrada.tipo || 'Tipo de café'}
              </option>
              {tipo?.length > 0 &&
                tipo.map((x) => (
                  <option key={x.id} value={x.tipo}>
                    {x.tipo}
                  </option>
                ))}
            </Select>
          </Flex>
        </SimpleGrid>
      </FormControl>
    </Card>
  );
}
