import {
  Button,
  Flex,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import { IoRemoveCircleOutline } from 'react-icons/io5';

const Header = forwardRef(({ id, deleteEntry, onShow, show }, ref) => {
  const textColor = useColorModeValue('secondaryGray.500', 'white');

  return (
    <Flex
      ref={ref}
      align="center"
      justify="space-between"
      w="100%"
      mb={'1rem'}
      px={'25px'}
      minH="60px"
    >
      <Flex alignItems="center" flexDirection="row" me="14px">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {'Registro ' + id}
        </Text>
        <Icon
          onClick={onShow}
          as={show ? GoChevronDown : GoChevronUp}
          color={textColor}
          w="24px"
          h="24px"
          ml={'10px'}
          fontWeight="700"
          cursor={'pointer'}
        />
      </Flex>
      <Tooltip hasArrow label="Eliminar registro" bg="gray.300" color="black">
        <Button onClick={() => deleteEntry(id)}>
          <IoRemoveCircleOutline fontSize="1.5rem" />
        </Button>
      </Tooltip>
    </Flex>
  );
});

export default Header;
