import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

export default function ConfirmModal({ isOpen, onClose, confirm }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth={'700px'}>
        {confirm.title && (
          <ModalHeader
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
            mt="20px"
            pb="0px"
            textAlign={'center'}
          >
            {confirm.title}
          </ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody mb={10}>
          <Divider w="80%" alignSelf="center" margin="auto" />

          {confirm.icon && (
            <Flex
              my="1rem"
              justifyContent="center"
              alignItems="center"
              w="50%"
              mx="auto"
            >
              {confirm.icon}
            </Flex>
          )}
          {confirm.subtitle && (
            <Flex justifyContent="center" alignItems="center" w="50%" mx="auto">
              <Text
                color="secondaryGray.600"
                fontSize="md"
                fontWeight="500"
                textAlign="center"
              >
                {confirm.subtitle}
              </Text>
            </Flex>
          )}
          {((confirm.primaryBtnAction && confirm.primaryBtnText) ||
            (confirm.secondaryBtnAction && confirm.secondaryBtnText)) && (
            <Flex
              mt="3.5rem"
              justifyContent="center"
              gap="20px"
              alignSelf="center"
              mx="auto"
            >
              {confirm.primaryBtnAction && confirm.primaryBtnText && (
                <Button
                  variant={'brand'}
                  _hover={{ opacity: 0.8 }}
                  onClick={confirm.primaryBtnAction}
                >
                  {confirm.primaryBtnText}
                </Button>
              )}
              {confirm.secondaryBtnAction && confirm.secondaryBtnText && (
                <Button
                  variant={'lightBrand'}
                  _hover={{ opacity: 0.8 }}
                  onClick={confirm.secondaryBtnAction}
                >
                  {confirm.secondaryBtnText}
                </Button>
              )}
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
